import React from "react";
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
// import styled from 'styled-components'

const SectionKaplica = () => (
    <StaticQuery 
        query = {graphql`
            query {
                kaplica: file(relativePath: { eq: "kaplica_inside_1.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1980) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                },
            }
        `}

        render = { data => {
            const imageData = data.kaplica.childImageSharp.fluid

            return (
              <BackgroundImage Tag="section" fluid={imageData} className="kaplica__section">
                <div className="container">
                    <div className="kaplica__section__content">
                        <h2>Elegancka Kaplica</h2>
                        <p>
                            Nasza firma posiada elegancką i największą w powiecie ostrowskim prywatną kaplicę,<br/>
                            w&nbsp;której w raz z najbliższymi mogą Państwo pożegnać się z szacunkiem i godnością z bliskimi,<br/>   
                            a&nbsp;także odmówić różaniec czy odprawić inne obrzędy w zależności od wyznania religijnego.
                        </p>
                        <a href="/kaplica">Sprawdź szczegóły</a>
                    </div>
                </div>
              </BackgroundImage>
            )
        }}
    />
);

export default SectionKaplica