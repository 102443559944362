import React from "react";
import { StaticQuery, graphql } from "gatsby"
import SectionTitle from "../SectionTitle";
import Lightbox from "../../utils/Lightbox"

const SectionGaleria = () => (
    <StaticQuery 
        query = {graphql`
        query {
            gallery: allFile(filter: {sourceInstanceName: { eq: "gallery" }}) {
              edges {
                node {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth:800,  quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}

        render = { data => {
            return (
                <div id="galeria">
                    <div className="container">
                        <div className="content text-center section-content">
                            <SectionTitle title="Galeria" bottom={false} />
                            <p>
                                Poniżej prezentujemy kilka fotografii prezentujących<br/>naszą firme i oferowane przez nią usługi.<br/><br/>
                            </p>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="galeria__section__content">
                          <Lightbox photos={data.gallery.edges} />
                        </div>
                    </div>
                    <div className="container">
                        <div className="content text-center section-content">
                            <p className="after">
                                Więcej zdjęc prezentujących firmę oraz oferowane przez nas usługi,<br/>a także flotę pojazdów znajdą Państwo klikając w przycisk poniżej.<br/><br/>
                            </p>
                            <p className="text-center">
                                <a href="/galeria" className="read-more" title="Foto galeria - ceremonie pogrzebowe, dom pogrzebowy, wystrój grobu">WIĘCEJ ZDJĘĆ</a><br/><br/>
                            </p>
                            <hr />
                        </div>
                    </div>
                </div>
            )
        }}
    />
);

export default SectionGaleria