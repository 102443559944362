import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const IconMarker = ({ text }) => (
  <div className="map-marker">
    {text}
    <div className="arrow"></div>
  </div>
);

class MultiMap extends Component {
  static defaultProps = {
    center: {
      lat: 51.65,
      lng: 17.81
    },
    zoom: 15
  };

  render() {
    return (
      <div style={{ height: '450px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyChAGiNYPrtfzMIFN9KMZHjq8hm_dHKEwQ' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <IconMarker lat={51.6543948} lng={17.8237} text="Biuro"/>
          <IconMarker lat={51.6471877} lng={17.7956} text="Dom pogrzebowy"/>
        </GoogleMapReact>
      </div>
    );
  }
}

export default MultiMap;