import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import { graphql } from "gatsby"
import SEO from "../components/Seo"
import SectionTitle from "../components/SectionTitle"
import SectionContact from "../components/SectionContact/SectionContact"
import SectionKaplica from "../components/SectionKaplica/SectionKaplica"
import SectionGaleria from "../components/SectionGaleria/SectionGaleria"
import SectionOdeszli from "../components/SectionOdeszli/SectionOdeszli"

const IndexPage = ({data}) => (
  <>
    <SEO title="Organizacja pogrzebu, pochówku, kremacji" />
    <HomeLayout data={data}>
        <div className="container">
            <div className="page-content content section-firma">
                <section className="container">
                    <SectionTitle title="O firmie" bottom={true} />
                    <p>
                    Naszą misją jest <strong>świadczenie profesjonalnych usług pogrzebowych na światowym poziomie</strong>. Oferowane przez naszą firmę usługi są <strong>pełne godności, poszanowania wobec Państwa bliskich</strong> i troski o każdego z naszych Klientów.
                    </p>
                    <p className="phones text-center">
                    Jesteśmy do Państwa dyspozycji całą dobę, 7 dni w tygodniu pod numerami:<br/>
                    <a href="phone:+48627371040" title="Telefon stacjonarny">62 737-10-40</a> oraz <a href="phone:+48797126860" title="Telefon komórkowy">+48 797-126-860</a>
                    </p>
                    <p>
                    Aby <strong>sprostać oczekiwaniom Klientów</strong> oraz <strong>iść z duchem czasu</strong>, oferujemy profesjonalny estetyczny <strong>wystrój grobu</strong> oraz <strong>nagłośnienie</strong> z możliwością odtwarzania pieśni żałobnych podczas ostatniego pożegnania w kaplicy, w drodze na cmentarz czy też bezpośrednio na nim.
                    &nbsp;<strong>Wystrój grobu</strong> podczas ceremonii pogrzebowej jest to nieodłączny element każdej ceremonii pogrzebowej organizowanej przez naszą firmę.
                    &nbsp;<strong>Troszczymy się o każdy szczegół</strong> ceremonii pogrzebowej, aby była ona <strong>piękna i dostojna</strong>. W tak trudnych chwilach służymy Państwu pomocą w każdej sferze organizacji ceremonii pogrzebowej.
                    </p>
                    <p className="text-center">
                        <a href="/firma" className="read-more" title="O nas - Usługi Pogrzebowe ABRAM">WIĘCEJ INFORMACJI</a> 
                    </p>
                </section>
            </div>
        </div>
        <SectionKaplica />
        <SectionGaleria />
        <SectionContact />
    </HomeLayout>
  </>
)
export const pageQuery = graphql`
    query {
        logo: file(relativePath: { eq: "logo_poziome.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default IndexPage
