import React from "react"
import SectionTitle from "../SectionTitle"
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MultiMap from '../Map/MultiMap'

const SectionContact = ({ image }) => (
    <StaticQuery 
        query = {graphql`
            query {
                logo: file(relativePath: { eq: "logo_poziome_dark.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
            }
        `}

        render = { data => (
            <div id="kontakt">
                <div className="container">
                    <div className="content">
                        <SectionTitle title="Kontakt" bottom={false} />
                        <p>
                        Jeżeli mają Państwo pytania zapraszamy serdecznie<br/>do kontaktu telefonicznego, e-mailowego lub poprzez socialmedia.
                        </p>
                        <Img className="contact__logo" fluid={data.logo.childImageSharp.fluid} alt=""/>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <p>
                                <strong>Usługi Pogrzebowe ABRAM</strong><br/>
                                Urszula Holewa
                            </p>
                            <p>
                                NIP: 622-100-36-78<br/>
                                REGON: 251620343
                            </p>
                            <p>
                                <strong>Biuro:</strong><br/>
                                ul. Limanowskiego 31<br/>
                                63-400 Ostrów Wielkopolski
                            </p>
                            <p>
                                <strong>Dom pogrzebowy:</strong><br/>
                                ul. Okólna 29<br/>
                                63-400 Ostrów Wielkopolski
                            </p>
                        </div>
                        <div className="column">
                            <p>
                                <strong>Telefony całodobowe:</strong><br/>
                                <a href="tel:+48627371040">+48 62 737-10-40</a><br/>
                                <a href="tel:+48797126860">+48 797-126-860</a><br/>
                            </p>
                            <p>
                                <strong>E-mail</strong><br/>
                                <a href="mailto:biuro@abram.net.pl">
                                    biuro@abram.net.pl
                                </a><br/><br/>
                            </p>
                            <p>
                                <strong>Social media:</strong>
                            </p>
                            <ul className="social-media">
                                <li>
                                    <a href="https://fb.me/abram.uslugi.pogrzebowe" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={['fab','facebook']} />    
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/abram_uslugi_pogrzebowe/" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={['fab','instagram']} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://wa.me/48661235125" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={['fab','whatsapp']} />   
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="contact">
                    <div className="contact__map-wrapper">
                        <MultiMap />
                    </div>
                </div>
            </div>
        )}
    />
)

export default SectionContact
